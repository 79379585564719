import styled from '@emotion/styled'
import { ChevronsDown, ChevronsUp } from 'app/components/Icons'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo } from 'react'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

export interface Props {
  description?: string
  isExpanded?: boolean
  title?: string
}

export const Item = memo(function Item({ description, title }: Props) {
  if (!title) {
    return null
  }

  return (
    <Container activeClassName="expanded">
      <Head>
        <Title>
          {title}
          <Button>
            <State>
              {({ expanded }) => (expanded ? <ChevronsUp /> : <ChevronsDown />)}
            </State>
          </Button>
        </Title>
      </Head>
      {description ? (
        <Panel>
          <State>
            {({ expanded }) => (
              <AnimatePresence>
                {expanded && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Description
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </State>
        </Panel>
      ) : null}
    </Container>
  )
})

const Container = styled(AccordionItem)``

const Head = styled(AccordionItemHeading)`
  border-top: 0.125rem solid
    ${({ theme }) => theme.colors.variants.neutralLight3};
  cursor: pointer;
`

const Title = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.1625rem;
  line-height: 1.125rem;
  padding: 1.625rem 1.875rem 1.625rem 3.25rem;
  position: relative;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.variants.neutralDark2};
  }
  &:before {
    content: '';
    width: 1.25rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    position: absolute;
    top: 2.1875rem;
    left: 1.25rem;
  }

  @media (max-width: 767px) {
    padding: 1.5rem 20px;
    &:before {
      content: none;
    }
  }
`

const Button = styled.div`
  margin-left: auto;

  svg {
    width: auto;
    height: 0.9375rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.primaryDark2};
    stroke-width: 2;
  }
`

const State = styled(AccordionItemState)``

const Panel = styled(AccordionItemPanel)`
  display: block !important;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  letter-spacing: -0.0313rem;
  line-height: 2.25rem;
  overflow: hidden;
  padding: 0 3rem 0 3.25rem;

  @media (max-width: 767px) {
    line-height: 1.75rem;
    font-size: 0.9375rem;
    padding: 0 1.25rem;
  }
`

const Description = styled.div`
  padding: 1.4375rem 0 3.75rem;

  @media (max-width: 767px) {
    padding: 0.625rem 0 2.5rem;
  }
`
