import styled from '@emotion/styled'
import React, { memo } from 'react'
import { Accordion } from 'react-accessible-accordion'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
}

export const Accordions = memo(function Accordions({ items }: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      <AccordionsWrap allowZeroExpanded>
        {items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </AccordionsWrap>
    </Container>
  )
})

const Container = styled.section`
  margin: -11.25rem auto 7.5rem;
  padding: 0 1.875rem;

  @media (max-width: 1023px) {
    margin: -5rem auto 7.5rem;
    padding: 0 1.25rem;
  }

  @media (max-width: 767px) {
    margin: -1.875rem auto 5.625rem;
  }
`

const AccordionsWrap = styled(Accordion)`
  max-width: 46.875rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralLight3};
  border-top: 0;
  margin: auto;

  > div {
    &.expanded {
      background: ${({ theme }) => theme.colors.variants.neutralLight3};
      transition: background 0.4s ease-in-out;
    }
  }
`
